import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faTwitter,
  faTwitch,
  faYoutube,
  faFacebook,
  faKickstarter,
  faReddit,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import Danger from '../../alerts/Danger';
import Wysiwyg from '../../form/wysiwyg';
import RequiredAsterisk from '../../form/required_asterisk';

function CampaignInfluencerForm({ campaignData, setCampaignData, errors }) {

    const handleInputChange = (e) => {
        setCampaignData({ ...campaignData, [e.target.name]: e.target.value });
    };

    const handleWysiwigInputChange = (name, value) => {
        setCampaignData(campaignData => ({ ...campaignData, [name]: value }));
    };

    const platforms = [
        { name: 'Instagram', key: 'instagram', icon: faInstagram },
        { name: 'Twitter', key: 'twitter', icon: faTwitter },
        { name: 'Twitch', key: 'twitch', icon: faTwitch },
        { name: 'YouTube', key: 'youtube', icon: faYoutube },
        { name: 'Facebook', key: 'facebook', icon: faFacebook },
        { name: 'Kick', key: 'kick', icon: faKickstarter },
        { name: 'Reddit', key: 'reddit', icon: faReddit },
        { name: 'TikTok', key: 'tiktok', icon: faTiktok },
    ];

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-header bg-secondary text-white">
                    <h3><FontAwesomeIcon icon="info-circle" className="me-2" /> Influencer Expectations</h3>
                </div>
                <div className="card-body">
                    <p className="lead">Expectations and outcomes can be established with the content creators to ensure the messaging and deliverables align with what you desire for your campaign. Use the fields below to specify how you want the content creator to craft content to market and promote your game.</p>

                    <hr />

                    <form>
                        {createInputField('title_creator', 'Title For Creators', 'This is the title creators and influencers will see when viewing your campaign.', 'text', true, errors)}
                        {createTextAreaField('brief', 'Brief', 'The brief is an overview about the game and the campaign. It should try to peak the interest in the influencer in why they should work with your game.', errors, true)}
                        {createTextAreaField('deliverables', 'Deliverables', 'Specify any specific deliverables that you want the influencer to execute during the campaign.', errors, false)}
                        {createInputField('days_to_complete', 'Days To Complete', 'The number of days that the influencer has to complete the deliverables. Leave blank or enter 0 for infinite.', 'number', false, errors)}
                        {createTextAreaField('requirements', 'Requirements', 'List any requirements you have of the creator for this campaign. This could range from requirements in their following size, social accounts, interest, and other areas.', errors)}
                        {createTextAreaField('hashtags', 'Hashtags', 'List any hashtags that you want the content creators to use.', errors)}
                        {createTextAreaField('highlights', 'Product Highlights', 'List any talking points that you want the creator to discuss in their content.', errors)}
                        {createTextAreaField('prohibited_content', 'Prohibited Content', 'List anything you want to prohibit the creators from doing in relation to your content.', errors)}
                        {createTextAreaField('usage_rights', 'Content Usage Rights', 'Specify the content ownership and usage rights for the content generated during the campaign.', errors)}

                        <h5 className="mt-4 text-black">Follower Requirements</h5>
                        <p>If you want to set optional follower requirements for an influencer, enter the values below. These values will be used by the AI when automatically inviting influencers.</p>
                        <div className="row">
                            {platforms.map(platform => (
                                <div className="col-md-6" key={platform.key}>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h6 className="card-title">
                                                <FontAwesomeIcon icon={platform.icon} className="me-2" />
                                                {platform.name}
                                            </h6>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label htmlFor={`${platform.key}_min_followers`} className="form-label">Min Followers</label>
                                                    <input 
                                                        type="number" 
                                                        min="0"
                                                        className="form-control" 
                                                        name={`${platform.key}_min_followers`} 
                                                        value={campaignData[`${platform.key}_min_followers`] || ''} 
                                                        onChange={handleInputChange} 
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor={`${platform.key}_max_followers`} className="form-label">Max Followers</label>
                                                    <input 
                                                        type="number" 
                                                        min="0"
                                                        className="form-control" 
                                                        name={`${platform.key}_max_followers`} 
                                                        value={campaignData[`${platform.key}_max_followers`] || ''} 
                                                        onChange={handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        
                    </form>
                </div>
            </div>
        </div>
    );

    function createInputField(name, label, description, type = 'text', required = false, errors) {
        return (
            <>
                <div className="mb-3">
                    <label className='fw-bold fw-bolder' htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>
                    
                    <input type={type} className="form-control" name={name} value={campaignData[name] || ''} onChange={handleInputChange} />
                    <p className="small">{description}</p>
                </div>
                {errors && errors[name] && errors[name].map((error, index) => (
                    <Danger message={error} key={index} />
                ))}
            </>
        );
    }

    function createTextAreaField(name, label, description, errors, required = false) {
        return (
            <>
                <div className="mb-3">
                    <label htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>

                    <Wysiwyg children={campaignData[name] || ''} name={name} id={name} onChange={(value) => {handleWysiwigInputChange(name, value)}} />
                    <p className="small">{description}</p>
                </div>
                {errors && errors[name] && errors[name].map((error, index) => (
                    <Danger message={error} key={index} />
                ))}
            </>
        );
    }
}

export default CampaignInfluencerForm;
