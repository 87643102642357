import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import CampaignLinksManager from '../../component/section/campaigns/campaign_links_manager';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import CampaignRateCard from '../../component/section/campaigns/campaign_rate_card';
import GameTitle from '../../component/section/titles/title_display';
import Navigate from '../../../../util/Navigate';
import Moment from 'react-moment';
import CampaignUserManager from '../../component/section/campaigns/campaign_users_manager';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignMentionsManager from '../../component/section/campaigns/campaign_mentions_manager';
import Breadcrumbs from '../../component/layout/breadcrumb';
import CampaignInviteManager from '../../component/section/campaigns/campaign_invites_manager';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';

const CampaignsViewPage = () => {
    const [campaign, setCampaign] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();

    const campaignObjectiveMap = {
        1: 'Brand Awareness',
        2: 'Audience Engagement',
        3: 'Lead Generation',
        4: 'Sales Conversion',
        5: 'Brand Identity & Reputation',
        6: 'Customer Loyalty & Retention',
        7: 'Content Amplification & Diversity',
        8: 'Market Feedback & Insight',
        9: 'Educating Audience',
        10: 'Community Building',
        11: 'Driving Web Traffic',
        12: 'SEO Benefits',
    };

    const influencerCampaignTypeMap = {
        1: 'Sponsored Content',
        2: 'Affiliate Marketing',
        3: 'Product Gifting',
        4: 'Brand Ambassador',
        5: 'Social Media Takeover',
        6: 'Contests & Giveaways',
        7: 'Event Coverage',
        8: 'Co-Creation of Products',
        9: 'Influencer Whitelisting',
        10: 'Social Issues & Cause Campaigns',
    };

    useEffect(() => {
        Glitch.api.Campaigns.view(id).then(response => {
            const updatedCampaign = {
                ...response.data.data,
                type: influencerCampaignTypeMap[response.data.data.type],
                objective: campaignObjectiveMap[response.data.data.objective],
            };
            setCampaign(updatedCampaign);
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Campaigns.listInfluencerCampaigns({ campaign_id: id }).then(response => {
            // Handle influencer campaigns list
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const shareLink = () => {
        let link = `${window.location.origin}${Navigate.influencersViewCampaignPage(campaign.id)}`;
        navigator.clipboard.writeText(link).then(() => {
            setCopySuccess('Link copied successfully!');
            setTimeout(() => setCopySuccess(''), 3000);
        }, () => {
            setCopySuccess('Failed to copy the link.');
            setTimeout(() => setCopySuccess(''), 3000);
        });
    };

    return (
        <>
            <PublisherHeader position={"relative"} />
            <Fragment>
                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                        ]}
                        />
                        <div className="section-wrapper">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title"><i className="fas fa-eye"></i> View Campaign</h2>
                            <p className="lead">View the information for this campaign.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container">
                    <div className="card mb-4">
                        <div className="card-header bg-primary text-white">
                            <h2>{campaign.name}</h2>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col-md-4">
                                    <img src={campaign?.title?.image_main || '/assets/images/titles/no_image_2.png'} className="img-fluid rounded" style={{ width: "100%" }} alt="Campaign" />
                                </div>
                                <div className="col-md-8">
                                    <h3 className='text-black'>General Information</h3>
                                    <p><strong>Description:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.description)} /></p>
                                    <p><strong>Brief:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.brief)} /></p>
                                    <p><strong>Status:</strong> {campaign.is_active ? 'Active' : 'Inactive'}</p>
                                    <p><strong>Type:</strong> {campaign.type}</p>
                                    <p><strong>Objective:</strong> {campaign.objective}</p>
                                    <p><strong>Community:</strong> {campaign?.community?.name}</p>
                                    <p><strong>Influencer Limit:</strong> {campaign.influencer_limit ? `A maximum of ${campaign.influencer_limit} influencers can sign-up to this campaign.` : 'Infinite number of influencers can sign up for this campaign.'}</p>
                                    <p><strong>Total Spend Limit:</strong> ${campaign.spend_limit}</p>
                                    <p><strong>Spend Limit Per Influencer:</strong> {campaign.spend_limit_per_influencer ? `$${campaign.spend_limit_per_influencer} is the maximum amount each influencer can make for this campaign.` : 'Influencers have no cap on how much they make for this campaign.'}</p>
                                    <p><strong>Share Link:</strong> <button onClick={shareLink} type='button' className='btn btn-info btn-sm'><i className="fa fa-copy"></i> Copy</button></p>
                                    {copySuccess && <div className="alert alert-success mt-2">{copySuccess}</div>}
                                    <small>Share this link with influencers</small>
                                </div>
                            </div>

                            {campaign.social_platforms && campaign.social_platforms.length && campaign.social_platforms.map > 0 && (
                                <>
                                    <hr />
                                    <h3>Social Platforms</h3>
                                    <ul>
                                        {campaign.social_platforms.map(platform => <li key={platform}>{platform}</li>)}
                                    </ul>
                                </>
                            )}

                            <hr />

                            <GameTitle gameInfo={campaign.title} promotionType={campaign?.promotion_type} />

                            <hr />

                            <CampaignRateCard campaign={campaign} />

                            <hr />

                            {(campaign.hashtags || campaign.highlights || campaign.prohibited_content) && (
                                <>
                                    <h3 className='text-black'>Content Creator Directional Information</h3>
                                    {campaign.deliverables && <p><strong>Deliverables:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.deliverables)} /></p>}
                                    {campaign.days_to_complete && <p><strong>Days To Complete:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.days_to_complete)} /></p>}
                                    {campaign.hashtags && <p><strong>Hashtags:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.hashtags)} /></p>}
                                    {campaign.highlights && <p><strong>Highlights:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.highlights)} /></p>}
                                    {campaign.prohibited_content && <p><strong>Prohibited Content:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.prohibited_content)} /></p>}
                                    {campaign.usage_rights && <p><strong>Usage Rights:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.usage_rights)} /></p>}
                                </>
                            )}

                            <hr />

                            <h3 className='text-black'>Additional Details</h3>
                            {campaign.start_date && <p><strong>Start Date:</strong> <Moment format="MM-DD-YYYY">{campaign.start_date}</Moment></p>}
                            {campaign.end_date && <p><strong>End Date:</strong> <Moment format="MM-DD-YYYY">{campaign.end_date}</Moment></p>}
                            {campaign.target_audience && <p><strong>Target Audience:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.target_audience)} /></p>}
                            {campaign.requirements && <p><strong>Requirements:</strong> <span dangerouslySetInnerHTML={createMarkup(campaign.requirements)} /></p>}
                            <p><strong>Currency:</strong> {campaign.currency}</p>
                        </div>
                    </div>
                </div>
            </Fragment>

            <CampaignLinksManager campaignID={id} />
            <CampaignMentionsManager campaignID={id} />

            <Footer />
        </>
    );
};

export default CampaignsViewPage;
