import Glitch from 'glitch-javascript-sdk';
import { Component, Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';

class CommunitiesAdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communities: [],
        };
    }

    componentDidMount() {
        Glitch.api.Communities.list({
            roles: [Glitch.constants.Roles.ADMINISTRATOR, Glitch.constants.Roles.SUPER_ADMINISTRATOR, Glitch.constants.Roles.MODERATOR]
        }).then((response) => {
            this.setState({ communities: response.data.data });
        }).catch(error => {
            console.error(error);
        });
    }

    render() {
        return (
            <>
                <Fragment>
                    <PublisherHeader position={"relative"} />
                    <section className="pageheader-section-min">
                        <div className="container text-center">
                            <h2 className="pageheader-title">My Business Accounts</h2>
                            <p className="lead">See and manage all your business accounts below.</p>
                            <Link className="btn btn-success mt-3" to={Navigate.communitiesCreatePage()}>
                                Create Business Account
                            </Link>
                        </div>
                    </section>

                    <div className="container mt-5">
                        <div className="row">
                            {this.state.communities && this.state.communities.length > 0 ? (
                                this.state.communities.map((community, index) => (
                                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                        <div className="card h-100">
                                            <img 
                                                src={community.banner_image || `/assets/images/cover/cover_${Math.floor(Math.random() * 27) + 1}.jpeg`} 
                                                className="card-img-top" 
                                                alt={community.name} 
                                            />
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    <Link to={Navigate.communitiesManagePage(community.id)}>{community.name}</Link>
                                                </h5>
                                                <p className="card-text">{community.description}</p>
                                                <p className="small text-muted">{community.tagline}</p>
                                            </div>
                                            <div className="card-footer d-flex justify-content-between align-items-center">
                                                <small className="text-muted">Created at: {new Date(community.created_at).toLocaleDateString()}</small>
                                                <Link className="btn btn-primary btn-sm" to={Navigate.communitiesManagePage(community.id)}>
                                                    Manage
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No business accounts found.</p>
                            )}
                        </div>
                    </div>
                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CommunitiesAdminList;
